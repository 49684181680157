@keyframes fade-in-from-top-left {
  from {
    transform: translate3d(-30px, -20px, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.fadeIn {
  &-enter {
    animation: 0.3s fadeIn linear both;

    &-done {
      opacity: 1;
    }
  }

  &-exit {
    animation: 0.3s fadeIn linear reverse both;

    &-exit {
      opacity: 0;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes spin-show {
  0% {
    transform: scale(0) rotateZ(180deg);
  }

  100% {
    transform: scale(1) rotateZ(360deg);
  }
}

@keyframes spin-hide {
  0% {
    transform: scale(1) rotateZ(0);
  }

  100% {
    transform: scale(0) rotateZ(180deg);
  }
}

.Slide {
  &--left {
    left: 0;

    &-enter {
      animation: 0.3s slide-left linear both;
    }

    &-exit {
      animation: 0.3s slide-left linear reverse both;
    }

    &-exit-done {
      display: none;
    }
  }

  &--right {
    right: 0;

    &-enter {
      animation: 0.3s slide-right linear both;
    }

    &-exit {
      animation: 0.3s slide-right linear reverse both;
    }

    &-exit-done {
      display: none;
    }
  }

  &--bottom-right {
    right: 0;

    &-enter {
      animation: 0.3s fadeIn linear both;
    }

    &-exit {
      animation: 0.3s fadeIn linear reverse both;
    }

    &-exit-done {
      display: none;
    }
  }

  &--modal {
    &-exit-done {
      display: none;
    }
  }
}

@keyframes slide-left {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slide-right {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes scale-up {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes jump-shaking {
  0% {
    transform: translateX(0) scale(0.5);
  }

  25% {
    transform: translateY(-8px);
  }

  35% {
    transform: translateY(-8px) rotate(15deg) scale(0.9);
  }

  55% {
    transform: translateY(0) rotate(-15deg) scale(0.7);
  }

  65% {
    transform: translateY(8px) rotate(15deg);
  }

  75% {
    transform: translateY(8px) rotate(-15deg);
  }

  100% {
    transform: translateY(0) rotate(0) scale(1);
  }
}

@keyframes fade-in-and-up-50px {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0 0 0 / 20%);
  }

  100% {
    box-shadow: 0 0 0 10px rgba(0 0 0 / 0%);
  }
}

$root: '.FlyoutContent';

#{$root} {
  padding-top: 45px;
  text-align: center;

  @include media-breakpoint-up(sm) {
    padding-top: 50px;
  }

  p {
    @include font($size: rem(13), $line-height: 1.54);

    @include media-breakpoint-up(md) {
      @include font($size: rem(15), $line-height: 1.6);
    }
  }

  &__heading {
    @include make-heading($size: rem(24), $name: 'body');

    @include media-breakpoint-up(sm) {
      @include make-heading($size: rem(28), $name: 'body');
    }

    + *:not(p) {
      margin-top: rem(20px);
    }
  }

  &__subHeading {
    @include make-heading(h4);

    margin-bottom: 1rem;

    &:first-child {
      margin-top: rem(8px);
    }
  }

  &__section {
    margin-bottom: 26px;
    padding: 26px 20px 0;

    @include media-breakpoint-up(sm) {
      margin-bottom: 36px;
      padding: 36px 30px 0;
    }

    + hr {
      margin-top: 40px;
    }
  }

  &__sectionImg {
    margin-right: auto;
    margin-left: auto;

    &:not(:first-child) {
      margin-top: 30px;
    }

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  .StoryBlock .ImageContainer {
    margin-right: -20px;
    margin-left: -20px;

    @include media-breakpoint-up(sm) {
      margin-right: -30px;
      margin-left: -30px;
    }
  }
}

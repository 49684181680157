// stylelint-disable selector-no-qualifying-type

//
// Textual form controls
//

.form-control {
  display: block;

  width: 100%;
  padding: $input-padding-y $input-padding-x;

  font-size: $font-size-base;
  line-height: $input-line-height;
  color: $input-color;

  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;

  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @if $enable-rounded {
    // Manually use the if/else instead of the mixin to account for iOS override
    border-radius: $input-border-radius;
  } @else {
    // Otherwise undo the iOS default
    border-radius: 0;
  }

  @include box-shadow($input-box-shadow);
  @include transition($input-transition);

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  @include form-control-focus($ignore-warning: true);

  // Placeholder
  &::placeholder {
    color: $input-placeholder-color;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly] {
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
    background-color: $input-disabled-bg;
  }
}

select.form-control {
  appearance: none;

  &:not([size], [multiple]) {
    height: $input-height;
  }

  &:focus::-ms-value {
    // Suppress the nested default white text on blue background highlight given to
    // the selected option text when the (still closed) <select> receives focus
    // in IE and (under certain conditions) Edge, as it looks bad and cannot be made to
    // match the appearance of the native widget.
    // See https://github.com/twbs/bootstrap/issues/19398.
    color: $input-color;
    background-color: $input-bg;
  }
}

// Make file inputs better match text inputs by forcing them to new lines.
// .form-control-file,
// .form-control-range {
//   display: block;
//   width: 100%;
// }

//
// Labels
//

// For use with horizontal and inline forms, when you need the label (or legend)
// text to align with the form controls.
.col-form-label {
  margin-bottom: 0; // Override the `<label>/<legend>` default
  padding-top: calc(#{$input-padding-y} + #{$input-border-width});
  padding-bottom: calc(#{$input-padding-y} + #{$input-border-width});

  font-size: inherit; // Override the `<legend>` default
  line-height: $input-line-height;
}

.col-form-label-lg {
  padding-top: calc(#{$input-padding-y-lg} + #{$input-border-width});
  padding-bottom: calc(#{$input-padding-y-lg} + #{$input-border-width});
  font-size: $font-size-lg;
  line-height: $input-line-height-lg;
}

.col-form-label-sm {
  padding-top: calc(#{$input-padding-y-sm} + #{$input-border-width});
  padding-bottom: calc(#{$input-padding-y-sm} + #{$input-border-width});
  font-size: $font-size-sm;
  line-height: $input-line-height-sm;
}

// Readonly controls as plain text
//
// Apply class to a readonly input to make it appear like regular plain
// text (without any border, background color, focus indicator)

.form-control-plaintext {
  display: block;

  width: 100%;
  margin-bottom: 0; // match inputs if this class comes on inputs with default margins
  padding-top: $input-padding-y;
  padding-bottom: $input-padding-y;

  line-height: $input-line-height;
  color: $input-plaintext-color;

  background-color: transparent;
  border: solid transparent;
  border-width: $input-border-width 0;

  &.form-control-sm,
  &.form-control-lg {
    padding-right: 0;
    padding-left: 0;
  }
}

// Form control sizing
//
// Build on `.form-control` with modifier classes to decrease or increase the
// height and font-size of form controls.
//
// The `.form-group-* form-control` variations are sadly duplicated to avoid the
// issue documented in https://github.com/twbs/bootstrap/issues/15074.

.form-control-sm {
  padding: $input-padding-y-sm $input-padding-x-sm;
  font-size: $font-size-sm;
  line-height: $input-line-height-sm;

  @include border-radius($input-border-radius-sm);
}

select.form-control-sm {
  &:not([size], [multiple]) {
    height: $input-height-sm;
  }
}

.form-control-lg {
  padding: $input-padding-y-lg $input-padding-x-lg;
  font-size: $font-size-lg;
  line-height: $input-line-height-lg;

  @include border-radius($input-border-radius-lg);
}

select.form-control-lg {
  &:not([size], [multiple]) {
    height: $input-height-lg;
  }
}

// Form groups
//
// Designed to help with the organization and spacing of vertical forms. For
// horizontal forms, use the predefined grid classes.

.form-group {
  margin-bottom: $form-group-margin-bottom;
}

.form-group legend {
  position: absolute;

  overflow: hidden;

  width: 1px;
  height: 1px;

  white-space: nowrap;
  // Visually hidden hack for a11y
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
}

.form-text {
  display: block;
  margin-top: $form-text-margin-top;
}

// Form grid
//
// Special replacement for our grid system's `.row` for tighter form layouts.

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;

  > .col,
  > [class*='col-'] {
    padding-right: 5px;
    padding-left: 5px;
  }
}

// Checkboxes and radios
//
// Indent the labels to position radios/checkboxes as hanging controls.

.form-check {
  position: relative;
  display: flex;

  &.center {
    align-items: center;
  }

  &.bottom {
    align-items: flex-end;
  }

  &.center,
  &.bottom {
    .form-check-input {
      margin-top: 0;
    }
  }

  &.underneath {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    padding-left: 0;

    input[type='radio'],
    input[type='checkbox'] {
      position: relative;
      display: block;
      margin: $grid-gutter-width 0 0 0;
    }

    input[type='radio'] ~ label {
      &::before {
        margin-right: 0;
      }

      &::after {
        bottom: 6px;
        left: auto;
      }
    }

    label {
      display: flex;
      flex-direction: column-reverse;
      padding-left: $form-check-input-gutter;
    }
  }

  &.hidden-input {
    padding-left: 0;

    input[type='radio'],
    input[type='checkbox'] {
      pointer-events: none;
      position: absolute;
      clip: rect(0, 0, 0, 0);
    }

    input[type='radio'] ~ label,
    input[type='checkbox'] ~ label {
      &::before,
      &::after {
        pointer-events: none;
        position: absolute;
        clip: rect(0, 0, 0, 0);
      }
    }
  }
}

.form-check-input {
  position: absolute;
  width: 20px;
  height: 20px;
  opacity: 0;

  ~ label {
    margin-bottom: 0; // Override default `<label>` bottom margin
  }

  &:disabled ~ label {
    color: $text-muted;
  }

  &[type='checkbox'] ~ label {
    position: relative;
    display: inline;
    padding-left: rem(31);

    &::before {
      content: '';

      position: absolute;
      left: 0;

      display: inline-block;
      flex: 0 0 20px;

      width: 20px;
      height: 20px;
      margin-right: rem(10);

      vertical-align: top;

      background-color: $white;
      border: 1px solid $gray-510;
      border-radius: 4px;
    }

    // Tick
    &::after {
      content: '';

      position: absolute;
      top: 4px;
      left: 7px;
      transform: rotate(45deg) translate(-15px, -15px);

      display: flex;
      align-items: center;

      width: 6px;
      height: 10px;

      opacity: 0;
      border-right: 2px solid $black;
      border-bottom: 2px solid $black;
    }
  }

  &[type='checkbox'],
  &[type='radio'] {
    outline: none;

    &.keyboardFocus:focus-visible ~ label::before {
      outline: 2px solid $black;
    }
  }

  &[type='checkbox'] {
    &.keyboardFocus:focus-visible ~ label::before {
      border: 2px solid $white !important;
    }
  }

  &[type='checkbox']:checked ~ label {
    &::before {
      content: '';

      display: inline-block;

      width: 20px;
      height: 20px;

      background-color: $blue-light;
      border: 2px solid $blue-light;
      border-radius: 4px;
    }

    &::after {
      transform: rotate(45deg) translate(0);
      opacity: 1;
      transition: all 200ms ease-in-out;
    }
  }

  &[type='checkbox']:disabled ~ label {
    color: $lightgrey2;

    &::before {
      cursor: default;
      background-color: transparent;
      border-color: $gray-010;
    }
  }

  &[type='checkbox'].invalid ~ label {
    &::before {
      border-color: $error;
    }
  }

  &[type='checkbox'].indeterminate ~ label::after {
    content: '';

    position: absolute;
    top: 4px;
    left: 7px;
    transform: rotate(90deg) translate(0);

    display: flex;
    align-items: center;

    width: 6px;
    height: 10px;

    opacity: 1;
    border-right: 4px solid $black;
    border-bottom: unset;

    transition: all 200ms ease-in-out;
  }

  &[type='checkbox'].indeterminate ~ label::before {
    content: '';

    position: absolute;
    left: 0;

    display: inline-block;
    flex: 0 0 20px;

    width: 20px;
    height: 20px;
    margin-right: 0.625rem;

    vertical-align: top;

    background-color: $blue-light;
    border: 1px solid $blue-light;
    border-radius: 4px;
  }

  &[type='radio'] {
    ~ label {
      position: relative;
      display: flex;
      align-items: center;

      &::before {
        content: '';

        position: relative;

        display: inline-flex;
        align-items: center;
        justify-content: center;

        width: 20px;
        // Use min-width of 20px, instead of a flex-basis of 20px with flex-shrink 0, because ie11 does not respect box-sizing when using flex-basis.
        // So in ie11 as the border expands, the whole element will expand too.
        min-width: 20px;
        height: 20px;
        margin-right: rem(10);

        background-color: $white;
        border: 1px solid $gray-510;
        border-radius: 50%;

        transition: all 175ms ease-in-out;
      }
    }

    &:checked,
    &.checked {
      ~ label::before {
        background-color: $black;
        border: 6px solid $blue-light;
      }
    }

    &:disabled {
      ~ label::before {
        background-color: $info;
      }
    }

    &.invalid {
      ~ label::before {
        border-color: $error;
      }
    }
  }
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  margin-right: rem(32);
  padding-left: 0; // Override base .form-check

  // Undo .form-check-input defaults and add some `margin-right`.
  .form-check-input {
    position: absolute;
    margin-top: 0;
    margin-left: 0;
  }
}

// Form validation
//
// Provide feedback to users when form field values are valid or invalid. Works
// primarily for client-side validation via scoped `:invalid` and `:valid`
// pseudo-classes but also includes `.is-invalid` and `.is-valid` classes for
// server side validation.

@include form-validation-state('valid', $form-feedback-valid-color, 'none');
@include form-validation-state('invalid', $form-feedback-invalid-color, 'none');

// Inline forms
//
// Make forms appear inline(-block) by adding the `.form-inline` class. Inline
// forms begin stacked on extra small (mobile) devices and then go inline when
// viewports reach <768px.
//
// Requires wrapping inputs and labels with `.form-group` for proper display of
// default HTML form controls and our custom form controls (e.g., input groups).

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; // Prevent shorter elements from growing to same height as others (e.g., small buttons growing to normal sized button height)

  // Because we use flex, the initial sizing of checkboxes is collapsed and
  // doesn't occupy the full-width (which is what we want for xxs grid tier),
  // so we force that here.
  .form-check {
    width: 100%;
  }

  // Kick in the inline
  @include media-breakpoint-up(sm) {
    label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
    }

    // Inline-block all the things for "inline"
    .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;

      margin-bottom: 0;
    }

    // Allow folks to *not* use `.form-group`
    .form-control {
      display: inline-block;
      width: auto; // Prevent labels from stacking above inputs in `.form-group`
      vertical-align: middle;
    }

    // Make static controls behave like regular ones
    .form-control-plaintext {
      display: inline-block;
    }

    .input-group,
    .custom-select {
      width: auto;
    }

    // Remove default margin on radios/checkboxes that were used for stacking, and
    // then undo the floating of radios and checkboxes to match.
    .form-check {
      display: flex;
      align-items: center;
      justify-content: center;

      width: auto;
      padding-left: 0;
    }

    .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: $form-check-input-margin-x;
      margin-left: 0;
    }

    .custom-control {
      align-items: center;
      justify-content: center;
    }

    .custom-control-label {
      margin-bottom: 0;
    }
  }
}

.TextField {
  position: relative;

  display: flex;
  flex-direction: column;

  margin-bottom: rem(20);
  padding-top: rem(5);

  font-size: rem(16);

  > label {
    pointer-events: none;

    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    transform-origin: top left;
    transform: translate(0, 28px) scale(1);

    padding: 0;

    font: inherit;
    font-size: rem(15);
    line-height: 1;
    color: $gray-600;

    transition: transform 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms;
  }

  &__container {
    display: flex;
    flex-direction: row;
    padding-top: rem(20);

    > * {
      display: flex;
      flex-grow: 1;

      + * {
        margin-left: rem(18);
      }
    }
  }

  &:has(select) {
    .TextField__loadingSkeletonWrapper {
      padding-right: 30px;
    }
  }

  &__loadingSkeletonWrapper {
    position: absolute;
    inset: 0;
    margin: 10px 0 0;
    font-size: 2rem;
  }

  &.loading {
    .TextField__input > :first-child {
      visibility: hidden;
    }
  }

  &__input {
    position: relative;
    display: inline-flex;

    &::after {
      pointer-events: none;
      content: ' ';

      position: absolute;
      right: 0;
      bottom: -5px;
      left: 0;

      color: transparent;

      border-bottom: 1px solid $gray-510;

      transition: border-bottom-color 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    > :first-child {
      display: block;
      flex-grow: 1;

      box-sizing: content-box;
      min-width: 0;
      margin: rem(10) 0 0;
      padding: rem(10) 0 rem(14);

      font: inherit;
      font-weight: 700;
      color: currentcolor;
      vertical-align: middle;

      appearance: none;
      background: none;
      border: 0;
      outline: none;

      -webkit-tap-highlight-color: transparent;

      &::placeholder {
        color: $gray-600;
        opacity: 0;
      }

      option {
        font-weight: normal;
      }
    }

    select {
      width: 100%;
      padding-right: rem(25);

      &::-ms-expand {
        display: none;
      }

      .Footer__currency & {
        background-position: top -1px right;
      }
    }
  }

  &__caret {
    pointer-events: none;

    position: absolute;
    top: calc(50% - 12px);
    right: 0;

    margin: 0;
    padding: 0;

    .Icon {
      fill: #c00;
    }
  }

  &__subText {
    padding-top: rem(10);
    font-size: rem(12);
    color: $gray-600;
  }

  &.error {
    .TextField__input {
      margin-bottom: 2px;
    }

    .TextField__input::after {
      border-bottom: 2px solid $error;
    }
  }

  &.disabled {
    color: $gray-300;

    > label {
      color: $lightgrey2;
    }

    .TextField__caret .Icon {
      fill: $lightgrey2;
    }
  }

  &.no-label {
    > .TextField__input {
      > :first-child {
        padding-top: 0;
      }

      &:has(select) {
        .TextField__loadingSkeletonWrapper {
          margin-top: 0;
          padding-top: 0;
        }
      }

      &:has(input) {
        .TextField__loadingSkeletonWrapper {
          margin: 0;
        }
      }
    }
  }

  &.focus,
  &.active {
    > label {
      top: -7px;
      transform: translate(0, 1.5px) scale(0.86);
    }

    .TextField__input input::placeholder {
      opacity: 1;
    }
  }

  &.focus {
    .TextField__input::after {
      border-bottom: 2px solid $black;
    }
  }

  &.isByKeyboard {
    .TextField__input::after {
      border-bottom: 2px solid $black;
    }

    input {
      border-radius: 3px;
      outline: 2px solid $black;
      outline-offset: -1px;
    }
  }
}

.TextArea {
  position: relative;

  display: flex;
  flex-direction: column;

  margin-bottom: rem(20);
  padding-top: rem(5);

  font-size: rem(15);

  > label {
    pointer-events: none;

    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    transform-origin: top left;
    transform: translate(0, 28px) scale(1);

    padding: 0;

    font: inherit;
    font-size: rem(15);
    line-height: 1;
    color: $gray-600;

    transition: transform 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms;
  }

  &__container {
    display: flex;
    flex-direction: row;
    margin-top: rem(36);
    padding-top: rem(20);

    > * {
      display: flex;
      flex-grow: 1;

      + * {
        margin-left: rem(18);
      }
    }

    textarea {
      resize: none;
      padding: 10px;
      background: transparent;
    }
  }

  &__input {
    position: relative;
    display: inline-flex;

    &::after {
      pointer-events: none;
      content: ' ';

      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;

      color: transparent;

      border-bottom: 1px solid $gray-510;

      transition: border-bottom-color 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    > * {
      display: block;
      flex-grow: 1;

      box-sizing: content-box;
      min-width: 0;
      margin: rem(10) 0 0;
      padding: rem(10) 0 rem(15);

      font: inherit;
      font-weight: 700;
      color: currentcolor;
      vertical-align: middle;

      appearance: none;
      background: none;
      border: 0;
      outline: none;

      -webkit-tap-highlight-color: transparent;

      &::placeholder {
        color: $gray-600;
        opacity: 0;
      }

      &.keyboardFocus:focus-visible {
        border-radius: 3px;
        outline: 2px solid $black;
        outline-offset: -2px;
      }

      option {
        font-weight: normal;
      }
    }

    select {
      width: 100%;
      padding-right: rem(25);

      &::-ms-expand {
        display: none;
      }

      .Footer__currency & {
        background-position: top -1px right;
      }
    }
  }

  &.error {
    color: $error;

    .TextArea__input::after {
      border-bottom: 2px solid $error;
    }
  }

  &.disabled {
    color: $gray-300;

    > label {
      color: $gray-400;
    }
  }

  &.no-label {
    > .TextArea__input {
      > * {
        padding-top: 0;
      }
    }
  }

  &.focus,
  &.active {
    .TextArea__input input::placeholder {
      opacity: 1;
    }
  }

  &.focus {
    .TextArea__input::after {
      border-bottom: 2px solid $black;
    }
  }
}

.PaymentForm {
  @include make-container-max-width();

  background: $white;

  &__border {
    @include card-box-shadow();
    @include card-border-radius();
    @include card-box-shadow();

    margin: 20px;
    padding: 20px;
    border-top: 1px solid $gray-300;
  }

  &__block {
    padding-bottom: 40px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  &__creditCard {
    display: flex;
    flex: wrap;
    flex-direction: column;
  }

  &__ccLogoContainer {
    position: absolute;
    top: -5px;
    right: 0;
    width: 50px;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__group {
    display: flex;
    flex: 1 100%;
    flex-direction: col;
  }

  &__container {
    flex: 1 1 50%;
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }
  }

  &__yearContainer {
    flex: 1 1 66.6666%;
    margin-right: 1.25rem;

    .TextField__container {
      padding-top: 0;
    }
  }

  &__cvvContainer {
    flex: 1 1 33.3333%;
  }

  &__copy {
    padding-top: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__confirm {
    display: flex;
    flex-direction: column;
  }

  &__submit {
    input {
      width: 100%;
    }
  }
}

@include media-breakpoint-up(md) {
  .PaymentForm {
    max-width: 70%;

    &__group {
      flex-direction: row;
    }

    &__creditCard {
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;

      div {
        // width: 95%;
        flex: 1;
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__address21 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      > div {
        flex: 1 66.6666%;
        margin-right: 20px;

        &:last-child {
          flex: 1 33.3333%;
          margin-right: 0;
        }
      }
    }

    &__address12 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      > div {
        flex: 1 32%;
        margin-right: 20px;

        &:last-child {
          flex: 1 68%;
          margin-right: 0;
        }
      }
    }

    &__confirm {
      flex-direction: row;
      justify-content: space-between;
      padding-top: 20px;
      border-top: 1px solid $gray-300;
    }

    &__submit {
      input {
        width: auto;
      }
    }
  }
}

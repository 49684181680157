button,
[type='button'],
[type='reset'],
[type='submit'] {
  appearance: initial !important;
}

:focus-visible:not(input, textarea, select) {
  // Setting the outline color for focus for different browsers
  outline: 2px solid $black;
}

p a {
  color: $black;
  text-decoration: underline;

  &:hover {
    color: $grey-text;
    text-decoration: underline;
  }

  .-dark &,
  .-black &,
  .-brass &,
  .-darkBrass {
    color: $white;
  }
}

hr {
  margin: 0;
  border: 0;
  border-top: 1px solid $grey-001;
}

.no-scroll {
  overflow: hidden;

  width: 100%;
  height: 100vh;

  border-right-color: transparent;
  border-right-style: solid;
}

.sr-only {
  @include sr-only();
}

body > [data-radix-popper-content-wrapper] {
  // Unfortunately, apart from "important" there is no other way to give the necessary specificity to such selector
  z-index: $z-index-tooltip !important;
}

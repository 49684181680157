.btn {
  user-select: none;

  display: inline-block;

  font-weight: $btn-font-weight;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: rem(1.77);
  white-space: nowrap;
  vertical-align: middle;

  border: $btn-border-width solid transparent;

  &::before {
    appearance: none !important;
  }

  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size-md, $btn-line-height, $btn-border-radius);
  @include transition($btn-transition);

  // default style
  @include make-button(transparent, $grey-text, $grey-text, $grey-text, $grey-text, $white, $grey-text, $grey-text);

  &.btn-full-width {
    overflow: hidden;
    width: 100%;
    padding: 10px 0;
  }

  &.btn-invert {
    @include make-button(
      transparent,
      $btn-base-color-invert,
      $btn-base-color-invert,
      $btn-base-color-invert,
      $btn-base-color-invert,
      $black,
      $btn-base-color-invert,
      $btn-base-color-invert,
      $btn-base-color-invert,
      $btn-base-bg-disabled-invert,
      $btn-base-bg-disabled-invert
    );

    &.disabled,
    &:disabled {
      opacity: 1;
    }
  }

  &:focus,
  &.focus {
    color: $grey-text;
  }

  // Share hover and focus styles
  @include hover {
    color: $white;
    text-decoration: none;
    outline: 0;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
  }

  // Opinionated: add "hand" cursor to non-disabled .btn elements
  &:not(:disabled, .disabled) {
    cursor: pointer;
  }

  &:not(:disabled, .disabled):active,
  &:not(:disabled, .disabled).active {
    &:focus,
    &.focus {
      outline: 0;
    }
  }

  // TODO: WHY? Can we safely remove this?
  &.has-children {
    padding: $btn-padding-y $btn-padding-x * 0.5;

    &:hover,
    &:focus,
    &.focus {
      padding: calc(#{$btn-padding-y} - 2px) calc((#{$btn-padding-x} / 2) - 2px);
    }

    > * {
      margin-right: rem(15);
    }
  }

  .Icon {
    width: auto;
    height: auto;
    line-height: 1;
    vertical-align: middle;
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

//
// Alternate buttons
//

$color: map-get($theme-colors, 'primary');

.btn-primary {
  @include make-button(
    $color,
    $color,
    $white,
    darken($color, 2%),
    darken($color, 2%),
    $white,
    darken($color, 2%),
    darken($color, 2%)
  );

  @include hover-focus {
    color: $white;
  }

  &:focus-visible {
    border: 2px solid $white;
  }

  &:not(:disabled, .disabled):active,
  &:not(:disabled, .disabled).active {
    &:focus,
    &.focus {
      border-color: $color;

      &::before {
        border-color: transparent;
      }
    }
  }

  &.btn-invert {
    @include make-button(
      $color,
      $color,
      $white,
      darken($color, 2%),
      darken($color, 2%),
      $white,
      darken($color, 2%),
      darken($color, 2%),
      $btn-base-color-invert,
      $btn-base-bg-disabled-invert,
      $btn-base-bg-disabled-invert
    );

    &:focus,
    &.focus {
      color: $white;
      border-color: $white;

      &::before {
        border-color: white;
      }
    }

    &.disabled,
    &:disabled {
      opacity: 1;
    }
  }

  // Theme variants
  &.-brass {
    @include make-button(
      $brass,
      $brass,
      $white,
      $white,
      $white,
      $white,
      $brass,
      $brass,
      $white,
      $btn-base-bg-disabled-invert,
      $btn-base-border-disabled,
      $btn-base-color-disabled
    );

    &:focus-visible {
      outline: 1px solid $white;

      &::before {
        border-color: $black;
      }
    }

    &:hover {
      color: $brass;
      border-color: $brass;
    }

    &:not(:disabled, .disabled):active,
    &:not(:disabled, .disabled).active {
      border-color: $brass;

      &:focus,
      &.focus {
        color: $white;
        background: $brass;

        &::before {
          border-color: $brass;
        }
      }
    }

    &.disabled,
    &:disabled {
      opacity: 1;
    }

    &.btn-invert {
      &:focus,
      &.focus {
        border-color: $black;

        &::before {
          border-color: $black;
        }
      }

      &:hover {
        color: $brass;
        border-color: $brass;
        border-width: 1px;

        &::before {
          border-color: transparent;
        }
      }

      &:not(:disabled, .disabled):active,
      &:not(:disabled, .disabled).active {
        border-color: $brass;

        &:focus,
        &.focus {
          color: $brass;
          background: $white;
          border-color: $brass;
          border-width: 1px;

          &::before {
            border-color: transparent;
          }
        }
      }

      &.disabled,
      &:disabled {
        color: $btn-base-color-disabled;
        opacity: 0.65;
        background: $btn-base-bg-disabled;
      }
    }
  }

  &.-black {
    @include make-button(
      $black,
      $black,
      $white,
      $white,
      $white,
      $black,
      $white,
      $white,
      $white,
      $btn-base-bg-disabled-invert,
      $btn-base-border-disabled,
      $btn-base-color-disabled
    );

    &:focus,
    &.focus {
      border-color: $white;

      &::before {
        border-color: $white;
      }
    }

    &:hover {
      color: $black;
      border-color: $black;
    }

    &:not(:disabled, .disabled):active,
    &:not(:disabled, .disabled).active {
      border-color: $black;

      &:focus,
      &.focus {
        color: $black;
        background: $white;
        border-color: $black;
      }
    }

    &.disabled,
    &:disabled {
      opacity: 1;
    }

    &.btn-invert {
      &:focus,
      &.focus {
        border-color: $brass;

        &::before {
          border-color: $brass;
        }
      }

      &:hover {
        color: $black;
        border-color: $black;
        border-width: 1px;

        &::before {
          border-color: transparent;
        }
      }

      &:not(:disabled, .disabled):active,
      &:not(:disabled, .disabled).active {
        border-color: $black;

        &:focus,
        &.focus {
          color: $black;
          background: $white;
          border-color: $black;
          border-width: 1px;

          &::before {
            border-color: transparent;
          }
        }
      }

      &.disabled,
      &:disabled {
        color: $btn-base-color-disabled;
        opacity: 0.65;
        background: $btn-base-bg-disabled;
      }
    }
  }
}

.btn-secondary {
  @include make-button(
    transparent,
    $color,
    $color,
    darken($color, 2%),
    darken($color, 2%),
    $white,
    darken($color, 2%),
    darken($color, 2%)
  );

  &:focus-visible {
    color: $color;
    border-color: $white;
    box-shadow: inset 0 0 0 1px $color;
  }

  &:focus,
  &.focus {
    color: $color;
  }

  &:hover,
  &.focus:hover,
  &:focus:hover {
    color: $white;
  }

  &.btn-invert {
    @include make-button(
      transparent,
      $white,
      $white,
      $white,
      $white,
      $black,
      $white,
      $white,
      $white,
      $btn-base-bg-disabled-invert,
      $btn-base-bg-disabled-invert
    );

    &.disabled,
    &:disabled {
      opacity: 1;
    }
  }

  // Theme variants
  &.-brass {
    @include make-button(transparent, $brass, $brass, $brass, $brass, $black);
  }

  &.-white {
    @include make-button(transparent, $white, $white, $white, $white, $black, $gray-410);

    &:focus-visible {
      color: $white;
      border-color: $white;
      border-width: 2px;
      box-shadow: inset 0 0 0 2px $lightgrey2;
    }

    &:hover {
      color: $black;
      border-color: transparent;
      outline: unset;
      box-shadow: unset;
    }
  }

  &.-grey {
    @include make-button(transparent, $lightgrey2, $lightgrey2, $white, $white, $black, $gray-410);

    &:focus-visible {
      color: $black;
      border-color: $white;
      border-width: 2px;
      box-shadow: inset 0 0 0 2px $lightgrey2;
    }

    &:hover {
      color: $black;
      box-shadow: unset;
    }
  }
}

// special case for refinements
.btn-refinement {
  @include make-button($white, $notification, $black, #eeeeee, $iron, $black, #eeeeee, $iron);

  padding: rem(8) rem(27) rem(8) rem(48);

  &:focus,
  &:hover {
    padding: rem(8) rem(27) rem(8) rem(48);
    color: $black;
    border-width: 1px;
  }
}

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  position: relative;

  font-size: inherit;
  font-weight: $font-weight-normal;
  color: $gray-600;
  text-transform: none;
  letter-spacing: normal;

  background: transparent;
  border: 0 none;

  &::before {
    content: none;
  }

  &::after {
    content: '';

    position: absolute;
    bottom: 20px;
    left: 30px;

    width: calc(100% - 60px);
    height: 1px;

    background: $grey-text;
  }

  @include hover {
    color: $black;
    background-color: transparent;
    border: 0;
  }

  &:not(:disabled, .disabled):active,
  &:not(:disabled, .disabled).active {
    color: $black;
    background-color: transparent;
    border: 0;

    &:focus {
      color: $black;

      &::after {
        bottom: 20px;
        height: 1px;
      }
    }
  }

  &:focus,
  &.focus {
    border-color: transparent;

    &::after {
      bottom: 19px;
      height: 2px;
    }
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
    color: $btn-link-disabled-color;
    background: transparent;
    border: 0;

    &::after {
      content: none;
    }
  }

  // No need for an active state here
}

.btn-icon {
  cursor: pointer;

  display: inline-flex;
  align-items: center;

  color: inherit;
  vertical-align: middle;

  background-color: transparent;
  border: 0;

  @include transition($btn-transition);

  &:hover {
    color: $link-color;
    text-decoration: none;
  }

  &.btn-invert {
    color: $white;

    .Icon {
      fill: currentcolor;
    }

    &:hover {
      color: $notification;
    }

    &.-dark {
      .Icon {
        fill: $btn-suites-color;
      }

      &:hover {
        color: $btn-suites-color;
      }
    }

    &.-brass {
      color: $black;

      .Icon {
        fill: $white;
      }

      &:hover {
        color: $white;
      }
    }
  }

  span:not(.Icon) {
    align-self: center;
    font-size: inherit;
  }

  .Icon {
    width: rem(25);
    height: rem(25);

    font-size: 0;

    fill: $link-color;

    transition:
      fill 0.3s,
      background 0.3s,
      border 0.3s;

    svg {
      vertical-align: middle;
    }
  }

  &.btn-cta {
    font-weight: $btn-font-weight;
    text-transform: uppercase;
    letter-spacing: rem(2.18);

    @include media-breakpoint-up(md) {
      line-height: 1.4;
    }

    .Icon {
      &.Pdf {
        width: rem(28);
        height: rem(28);
      }

      &.Arrow {
        width: rem(34);
        height: rem(34);

        .-darkBrass & {
          fill: $brass;
        }
      }
    }

    &.btn-invert {
      &:hover {
        color: $notification;
      }
    }

    &.-dark {
      color: $white;

      .Icon {
        fill: $btn-suites-color;
      }

      &:hover {
        color: $btn-suites-color;
      }
    }

    &.-brass {
      color: $black;

      .Icon {
        fill: $white;
      }

      &:hover {
        color: $white;
      }
    }

    &:hover {
      color: $vv-red;
      text-decoration: none;

      .-darkBrass & {
        color: $brass;
      }

      .-brass & {
        color: $white;
      }
    }

    @include button-size(
      $btn-padding-y * 0.5,
      $btn-padding-x * 0.5,
      $btn-font-size-md,
      $btn-line-height,
      $btn-border-radius
    );

    .Onboard &,
    &.Destinations__cta {
      &:hover {
        color: $notification;

        .Icon {
          svg {
            fill: $notification;
          }
        }
      }
    }
  }

  &.NewsletterSignupForm__button {
    border: 0;

    &::before {
      content: none;
    }

    &:focus,
    &.focus {
      color: $vv-red;

      &::before {
        content: none;
      }
    }

    &:hover {
      color: $vv-red;
      background-color: transparent;
    }

    &:not(:disabled, .disabled):active,
    &:not(:disabled, .disabled).active {
      color: $vv-red;
      background-color: transparent;
    }
  }

  &.FooterNewsletter__button {
    &::before {
      content: none;
    }

    .Icon {
      &:hover,
      &:focus,
      &.focus {
        fill: $notification;
      }
    }

    &:focus,
    &.focus {
      color: $notification;
      text-decoration: none;
      outline: #3b99fc auto 5px !important;

      &::before {
        content: none;
      }

      .Icon {
        fill: $notification;
      }
    }

    &:hover {
      color: $notification;
      text-decoration: none;
      background-color: transparent;

      .Icon {
        fill: $notification;
      }
    }

    &:not(:disabled, .disabled):active,
    &:not(:disabled, .disabled).active {
      background-color: transparent;
    }
  }

  * + * {
    margin-left: rem(9);
  }

  &.-leftAlign {
    padding-left: 0;
  }

  &.-rightAlign {
    padding-right: 0;
  }
}

.btn-border {
  .Icon {
    line-height: 0;
    background: $white;
    border: 1px solid $progress-divider;
    border-radius: 50%;

    &.Delete,
    &.Download,
    &.Edit {
      width: 30px;
      height: 30px;
      padding: 6px;
    }
  }

  .-dark &,
  .-brass & {
    .Icon {
      fill: $brass;
    }
  }

  &:hover {
    .Icon {
      background: $link-color;
      border-color: $link-color;
      fill: $white;

      .-dark & {
        background: $brass;
        border-color: $brass;
        fill: $white;
      }

      .-brass & {
        background: $black;
        border-color: $black;
        fill: $white;
      }
    }

    @media (hover: none) {
      .Icon {
        background: $white;
        fill: $vv-red;
      }
    }
  }

  &.btn-invert {
    .Icon {
      fill: $black;
    }

    &:hover {
      .Icon {
        background: $black;
        border-color: $white;
        fill: $white;
      }
    }

    &.-dark,
    &.-brass {
      .Icon {
        background: $white;
        fill: $brass;
      }

      &:hover {
        .Icon {
          background: $brass;
          fill: $white;
        }
      }
    }
  }

  &.keyboardFocus:focus-visible {
    .Icon {
      border: 2px solid $white;
      outline: 2px solid $black;
    }
  }
}

//
// Button Sizes
//

.btn-lg {
  @include button-size(
    $btn-padding-y-lg,
    $btn-padding-x-lg,
    $btn-font-size-md,
    $btn-line-height-lg,
    $btn-border-radius
  );

  padding: 16px 32px;
}

.btn-sm {
  padding: 6px 20px;
  font-weight: 400;
  text-transform: none;

  @include button-size(
    $btn-padding-y-sm,
    $btn-padding-x-sm,
    $btn-font-size-sm,
    $btn-line-height-sm,
    $btn-border-radius
  );
}

//
// Block button
//

.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}

// Specificity overrides
input[type='submit'],
input[type='reset'],
input[type='button'] {
  &.btn-block {
    width: 100%;
  }
}

.CloseBtn {
  padding: 0;
  line-height: 1;
  background-color: transparent;
  border: 0;

  .Close {
    cursor: pointer;
    color: $grey-text;

    .-dark & {
      color: $white;
    }

    &:hover {
      color: $black;

      .-dark & {
        color: $btn-base-color-disabled;
      }
    }
  }

  &.-invert .Close {
    color: $white;

    &:hover {
      color: $btn-base-color-disabled;
    }
  }
}

.btn-text {
  padding: 0;

  line-height: inherit;
  text-decoration: $link-hover-decoration;

  background: none;
  border: 0;

  &:hover {
    color: $grey-text;
  }
}

.Btn__google {
  padding: $btn-padding-y $btn-padding-x * 0.5 !important;
  color: $grey-text;
  border-color: rgba($grey-text, 0.39);

  &:focus {
    border-color: $grey-text;

    &::before {
      border-color: $grey-text;
    }
  }

  &:not(:disabled, .disabled):active,
  &:not(:disabled, .disabled).active,
  &:hover {
    padding: $btn-padding-y $btn-padding-x * 0.5 !important;
    color: $black;
    background: transparent;
    border: 1px solid $black;

    &::before {
      border-color: transparent;
    }
  }

  &:not(:disabled, .disabled):active,
  &:not(:disabled, .disabled).active {
    &::before {
      border-color: transparent;
    }

    &:focus,
    &.focus {
      padding: $btn-padding-y $btn-padding-x * 0.5 !important;
      color: $grey-text;
      border-color: rgba($grey-text, 0.39);

      &::before {
        border-color: transparent;
      }
    }
  }
}

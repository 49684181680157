@mixin sr-only {
  position: absolute;

  overflow: hidden;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  font-size: 0; // Voice Over hack to avoid drawing border on sr-only text
  white-space: nowrap;

  clip: rect(0, 0, 0, 0);
  border-width: 0;
}

$animation-duration: 300ms;

$root: '.SubNav';

#{$root} {
  position: relative;
  z-index: 1000;

  width: 100%;
  height: $subnav-height;

  text-align: center;

  background: $white;
  border-top: 1px solid $gray-010;

  transition: height $animation-duration;

  // Drop shadow
  &::after {
    content: '';

    position: absolute;
    bottom: -4px;
    left: 0;

    width: 100%;
    height: 4px;

    background: linear-gradient(to bottom, $black-001 0%, rgb(0 0 0 / 0%) 100%);
  }

  .js-stickybit-parent > &:not(.js-is-sticky) {
    top: 0 !important;
  }

  &__container {
    position: relative;

    overflow: auto hidden;
    display: flex;
    align-items: center;

    height: 100%;
    padding-left: 20px;
    // add iOS inertia
    -webkit-overflow-scrolling: touch;
    // hide scrollbars in Edge until element is hovererd
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  &__title {
    display: inline-block;

    margin-right: 30px;
    margin-bottom: 0;

    font-size: rem(15);
    font-weight: 400;
    white-space: nowrap;

    transition: all $animation-duration;
  }

  &__listWrapper {
    display: inline-block;
  }

  &__list {
    display: flex;
    align-content: space-between;

    max-width: 780px;
    margin-bottom: 0;
    padding: 0 20px 0 0;

    white-space: nowrap;
    list-style: none;
  }

  &__item {
    position: relative;

    display: flex;
    flex-shrink: 0;
    align-items: center;

    min-width: 0;
  }

  &__item + &__item {
    margin-left: 30px;

    @include media-breakpoint-up(sm) {
      margin-left: 100px;
    }
  }

  &__item a {
    @include font(rem(15), $weight: 700, $color: inherit);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  // Only add hover states if non-touch device
  &__item a:hover {
    text-decoration: none;
  }

  .has-hover &__item a:hover {
    color: $vv-red;

    &.-darkBrass {
      color: $brass;
    }
  }

  &__item a.-active {
    color: $vv-red;
    text-decoration: none;

    &.-darkBrass {
      color: $brass;
    }
  }

  &__activeUnderline {
    position: absolute;
    bottom: 0;

    height: 2px;

    background-color: $vv-red;

    transition: all $animation-duration ease-in-out;

    &.-darkBrass {
      background: $brass;
    }
  }

  // Pictorial variant
  &.-pictorial {
    height: $subnav-height;

    #{$root}__title {
      position: static;
    }

    #{$root}__item {
      margin-top: 0;
      font-size: rem(14);
      transition: all $animation-duration;
    }

    #{$root}__image {
      width: 0;
      height: 0;
      margin-bottom: 0;

      opacity: 0;

      transition: all $animation-duration;
    }

    // Expanded state
    &.-expanded {
      will-change: height;
      height: $subnav-height-expanded-mobile;

      #{$root}__image {
        width: 90px;
        height: auto;
        margin-bottom: 10px;
        opacity: 1;
      }

      #{$root}__title {
        position: absolute;
        top: 25px;
      }

      #{$root}__list {
        padding-top: 18px;
        padding-bottom: 12px;
      }

      #{$root}__item {
        margin-top: 40px;
      }
    }
  }

  // Variants
  &.-noBorder {
    border-top: 0;
  }

  @include media-breakpoint-up(sm) {
    &__title {
      margin-right: 40px;
    }

    &.-centered {
      // justify-content: center causes strange behaviour when using overflow scroll, so
      // use margins to center
      #{$root}__title {
        margin-left: auto;
      }

      #{$root}__listWrapper {
        margin-right: auto;
        margin-left: auto;
      }

      #{$root}__title + #{$root}__listWrapper {
        margin-left: 0;
      }
    }

    &:not(.-centered) {
      #{$root}__container {
        padding-left: 12.5vw;
      }
    }

    &.-pictorial {
      &.-expanded {
        height: $subnav-height-expanded-desktop;

        #{$root}__image {
          width: 117px;
        }

        #{$root}__title {
          position: static;
          transform: translateY(-2px);
        }

        #{$root}__item {
          margin-top: 0;

          a {
            align-items: center;
          }
        }

        #{$root}__item + #{$root}__item {
          margin-left: 100px;
        }
      }
    }
  }
}

@import 'import';
@import 'bootstrap/scss/reboot';
@import 'modules';
@import 'components';
@import 'base/animations';

body {
  overscroll-behavior-x: none;
  // FONTS => for macOS chrome users => as new font files font-smooth prop differes from ols ones
  -webkit-font-smoothing: antialiased;

  @include overflow-hidden-max-width;

  &[data-scroll-locked]._react-remove-scroll-bar-android-fix {
    --removed-body-scroll-bar-size: 0;

    margin-right: 0 !important;
  }
}

// fix uplift pointer-events for vaul drawer
#up-modal-backdrop {
  pointer-events: auto !important;
}

.Layout__content {
  position: relative;
}

#nprogress:is(:last-child) .bar {
  z-index: $z-index-next-progress-bar;
  height: 2px;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

@include media-breakpoint-down(sm) {
  .-refinementOpen #QSIFeedbackButton-btn,
  .-advFiltersOpen #QSIFeedbackButton-btn,
  .-sailorsFormOpen #QSIFeedbackButton-btn {
    display: none;
  }
}

$root: '.Popover';
$popover-color: $white;

#{$root} {
  &__backdrop {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    visibility: hidden;
    opacity: 0;
    background-color: rgba($black, 0.7);

    transition:
      visibility 0s 0.5s,
      opacity 0.5s;
  }

  &.-open {
    #{$root}__backdrop {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.5s;
    }
  }

  &.-fullscreen {
    #{$root} {
      &__header,
      &__close {
        display: block;
      }

      &__container {
        position: fixed !important;
        top: 0 !important;
        left: 0 !important;
        transform: none !important;

        width: 100%;
        height: 100vh;
        max-height: 100%;
      }
    }
  }

  &__close {
    &:not(.-newFilter) {
      position: absolute;
      top: rem(18);
      left: rem(18);

      display: none;

      line-height: 0;

      @include media-breakpoint-down(md) {
        display: block;
      }
    }

    &.-newFilter {
      position: absolute;
      top: 27.5px;
      right: 27.5px;

      display: none;

      line-height: 0;

      @include media-breakpoint-down(sm) {
        display: block !important;

        .Close {
          width: 15px !important;
          height: 15px !important;
          color: $black;
        }
      }
    }
  }

  &__reference {
    display: inline-block;
    background: transparent;
    border: 0;

    &.-newFilter {
      width: 100%;
    }
  }

  &__header {
    @include font($size: rem(24), $name: 'body', $weight: 400, $color: $black);

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__title {
    display: block;
    margin-top: rem(20);

    @include media-breakpoint-down(lg) {
      margin-bottom: 3.1875rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;

    text-align: center;

    // this adds scrolling for content that overlaps on really small screens
    @include media-breakpoint-down(md) {
      overflow-y: scroll;
      justify-content: flex-start;
    }

    @include media-breakpoint-up(lg) {
      text-align: left;
    }
  }

  &__container {
    z-index: 1001;

    overflow-x: auto;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: rem(22) rem(22) rem(30);

    text-transform: initial;

    opacity: 0;
    background-color: $popover-color;

    transition: opacity 0.3s;

    &:not(.-newFilter) {
      @include media-breakpoint-down(md) {
        position: fixed !important;
        top: 0 !important;
        left: 0 !important;
        transform: none !important;

        width: 100%;
        height: 100%;
      }
    }

    &.-newFilter {
      @include media-breakpoint-up(md) {
        background-color: #fff;
        border-radius: 3px;
        box-shadow:
          0 2px 8px 0 $black-0004,
          0 4px 20px 0 rgb(0 0 0 / 9%);
      }

      @include media-breakpoint-down(md) {
        will-change: transform;

        position: absolute;
        top: 0;
        left: 0;
        transform: translate3d(13px, 485px, 0);
      }

      @include media-breakpoint-down(sm) {
        position: fixed !important;
        top: 0 !important;
        left: 0 !important;
        transform: none !important;

        width: 100%;
        height: 100%;
      }
    }

    &.fadeIn-enter,
    &.fadeIn-enter-done {
      z-index: 99999;
      display: block;
      opacity: 1;
    }

    @include media-breakpoint-up(md) {
      padding: rem(15);
    }

    @include media-breakpoint-up(lg) {
      filter: drop-shadow(0 1px 3px rgb(190 42 42 / 20%));
      border-radius: 3px;
    }

    &[x-placement*='bottom'] #{$root}__arrow {
      top: -12px;

      &::before {
        transform: translateX(-50%);
        border-color: transparent transparent $popover-color;
        border-width: 0 12px 12px;
      }
    }

    &[x-placement*='top'] #{$root}__arrow {
      bottom: -12px;

      &::before {
        transform: translateX(-50%);
        border-color: $popover-color transparent transparent transparent;
        border-width: 12px 12px 0;
      }
    }

    &[x-placement*='right'] #{$root}__arrow {
      left: -12px;

      &::before {
        transform: translateY(-50%);
        border-color: transparent $popover-color transparent transparent;
        border-width: 12px 12px 12px 0;
      }
    }

    &[x-placement*='left'] #{$root}__arrow {
      right: -12px;
      transform: translateY(-50%);

      &::before {
        border-color: transparent transparent transparent $popover-color;
        border-width: 12px 0 12px 12px;
      }
    }

    &[x-placement*='top'],
    &[x-placement*='bottom'] {
      #{$root}__arrow {
        height: 12px;
      }
    }

    &[x-placement*='left'],
    &[x-placement*='right'] {
      #{$root}__arrow {
        width: 12px;
      }
    }
  }

  &__arrow {
    position: absolute;
    display: none;
    width: 0;
    height: 0;

    &::before {
      content: '';

      display: block;

      width: 0;
      height: 0;
      margin: auto;

      border-style: solid;
    }

    &:not(.-newFilter) {
      @include media-breakpoint-up(lg) {
        display: inline-block;
      }
    }

    &.-newFilter {
      display: none;
    }
  }
}

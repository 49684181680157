$desktop-breakpoint: map-get($grid-breakpoints, sm);

$root: '.Flyout';

aside {
  position: relative;
  z-index: 2000;
}

body .embeddedServiceSidebar.modalContainer {
  z-index: $z-index-chat;
}

#{$root} {
  &__container {
    position: relative;
    z-index: 1000000;
  }

  &__content {
    position: fixed;
    z-index: $z-index-flyout !important;
    top: 0;

    overflow-y: auto;

    width: 100%;
    height: 100%;

    background-color: $white;

    transition: width $flyout-animation-duration;

    -webkit-overflow-scrolling: touch;

    #{$root}.-adaptive-height & {
      top: 50%;
      height: auto;

      @include media-breakpoint-down(xxs) {
        transform: translate(0, -50%);
      }
    }

    @include media-breakpoint-up(sm) {
      width: $flyout-width-desktop;
    }

    &.-left {
      left: 0;
    }

    &.-right {
      right: 0;
      box-shadow:
        rgb(0 0 0 / 16%) 0 0 10px,
        rgb(0 0 0 / 23%) 0 0 6px;
    }

    &.-modal {
      left: 0;

      #{$root}.-adaptive-height & {
        min-height: 10vh;
      }

      @media (min-width: $desktop-breakpoint) and (min-height: 650px) {
        min-height: 60vh;
      }

      @media (min-width: $desktop-breakpoint) and (min-height: 950px) {
        min-height: 40vh;
      }

      @include media-breakpoint-up(sm) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        height: auto;
        min-height: 80vh;
        max-height: 80vh;
      }
    }
  }

  &__backdrop {
    cursor: pointer;

    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    &.-dark {
      background-color: rgba($black, 0.8);
    }
  }

  &__dismiss {
    position: absolute;
    z-index: 100;
    top: rem(16);
    left: rem(16);

    .Icon.Close {
      width: 30px;
      height: 30px;
    }

    &.-alignButtonRight {
      right: rem(16);
      left: unset;
    }

    &.-hideCrossButton {
      display: none;
    }

    &.-isStickyCloseButton {
      position: sticky;
    }
  }

  &.-card {
    #{$root}__dismiss {
      top: rem(59);
    }

    @include media-breakpoint-down(sm) {
      .Card__content {
        box-shadow: none;
      }
    }

    @include media-breakpoint-up(sm) {
      #{$root}__content {
        background: none;
      }
    }
  }

  &.-video {
    #{$root}__content {
      background-color: transparent;

      &.-modal {
        > div {
          top: 50%;
          transform: translateY(-50%);

          @include media-breakpoint-up(sm) {
            transform: none;
          }
        }

        @include media-breakpoint-up(sm) {
          width: 80%;
          height: auto;
          min-height: unset;
          max-height: unset;
        }

        @media (min-width: $desktop-breakpoint) and (min-height: 650px) {
          min-height: unset;
        }

        @media (min-width: $desktop-breakpoint) and (min-height: 950px) {
          min-height: unset;
        }
      }
    }

    #{$root}__dismiss {
      &.-alignButtonRight {
        right: rem(16);
        left: unset;
      }
    }
  }

  &__stickyFooter {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    a {
      display: block;

      height: rem(70);

      text-align: center;
      text-decoration: underline;

      background: #f1eeee;

      @include font($size: rem(15), $name: 'section', $color: $grey-scale, $line-height: rem(70));

      &:hover,
      &:active {
        color: $vv-red;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .Flyout_width {
      width: $flyout-payment-width-desktop;
    }
  }
}

.Icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  fill: currentcolor;

  svg {
    width: auto;
    height: auto;
  }

  &.Google {
    width: 16px;
    height: 16px;
  }
}

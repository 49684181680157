$root: '.Share';

#{$root} {
  margin-top: 20px;

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__itineraryResults {
    position: absolute;
    right: 34px;
    bottom: 22px;

    .button__itineraryResults {
      padding: 0 6px;
      color: $info-icon-background;
      background-color: #fff;
      border: 0;
    }

    .button__itineraryResults:focus {
      outline: none;
    }

    @media (min-width: $container-sm-width) and (max-width: $container-md-width) {
      bottom: 22px;
      left: 180px;
    }

    @media (max-width: $container-sm-width) {
      position: unset;
    }
  }

  &__preCheckoutPage {
    // margin-right: 30px;
    position: relative;

    .button__preCheckoutPage {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 40px;
      height: 40px;
      padding-bottom: 10px;

      background-color: $white;
      border: 0;
      border-radius: 50%;
      box-shadow: 0 0.125rem 0.25rem $black-003;

      .SocialShare {
        width: 18px;
        height: 18px;

        svg {
          width: 18px;
          height: 18px;
          fill: $black;
        }
      }

      @media (max-width: $container-xxxs-width) {
        width: rem(48);
        height: rem(48);
      }
    }

    .button__preCheckoutPage:focus {
      outline: none;
    }
  }

  &__itinerary {
    position: relative;
  }

  &__img {
    width: 100%;
    height: auto;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  &__button {
    width: 100%;
    margin: 0;
    padding: 0 !important;

    background: none;
    border: 0;
    border-radius: 50% !important;

    &:hover {
      padding: 0 !important;
      background: $vv-red;
      border: 0;

      svg {
        fill: $white;
      }
    }

    &:focus {
      padding: 0 !important;
      background: $vv-red;
      border-radius: 50%;

      &::before {
        border-color: transparent;
      }

      svg {
        fill: $white;
      }
    }

    &:not(:disabled, .disabled):active,
    &:not(:disabled, .disabled).active {
      background-color: $vv-red;

      &:focus {
        background-color: $vv-red;

        svg {
          fill: $white;
        }
      }
    }
  }

  &__heading {
    @include font($size: rem(17), $weight: 'bold', $line-height: 1.29);

    width: 45%;
    margin: 0 auto rem(42);
  }

  &__copy {
    max-width: 360px;
    margin: 20px auto 0;

    h2 {
      @include font($size: rem(17), $name: 'section');

      overflow-wrap: break-word;
    }

    p {
      @include font($size: rem(12), $name: 'body');
    }
  }

  &__lightbox {
    &.Lightbox__content {
      min-width: 280px;
      max-width: 400px;
      background: transparent;
    }
  }

  &__icon {
    position: relative;
    height: 40px;

    &::after {
      content: '';

      position: absolute;
      top: 20px;
      left: 0;

      width: 100%;
      height: 1px;

      background: $share-border;
    }
  }

  .Popover__container {
    position: relative !important;

    display: none;

    width: 220px;
    height: auto;
    padding-bottom: 0.5rem;

    text-align: center;

    visibility: visible;
    opacity: 1;
    filter: drop-shadow(0 1px 3px $black-012);
    border-radius: 4px;

    &[x-placement*='right'] {
      top: -40px !important;
      left: 70px !important;

      .Popover__arrow {
        top: 21px;
        left: -11px;
      }
    }

    &[x-placement*='top'] {
      top: unset !important;
      bottom: 60px !important;
      margin: 0 auto;

      .Popover__arrow {
        bottom: -12px;
        left: 50%;
      }

      .xnks__icons {
        justify-content: space-evenly;
      }
    }
  }

  &.-fomo {
    &__container {
      flex-direction: column;
    }
  }

  &.-scrolling {
    position: relative;
    z-index: 3;
    width: 40px;
    margin: 0;

    > .Share__scrolling {
      display: inline-block;
      border: 10px solid transparent;
    }

    &.-active {
      width: 290px;
    }

    .Popover__container {
      position: absolute !important;
      top: 10px !important;
    }

    + div,
    + section {
      margin-top: rem(-60);
    }
  }

  &.-itinerary {
    position: relative;
    margin: rem(84) 0 rem(80);
    text-align: center;

    .Popover__container {
      position: absolute !important;
      left: 50% !important;
      transform: translateX(-50%) !important;

      max-height: none;
      padding: 1rem 1rem 0.25rem;
    }
  }

  &.-active {
    .popContainer {
      display: block;
      visibility: visible;
    }

    .Popover {
      &__container {
        display: block;
        visibility: visible;
      }

      &:not(:disabled, .disabled):active,
      &:not(:disabled, .disabled).active {
        display: none;
      }

      &__arrow {
        display: block;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  #{$root} {
    &__container {
      display: flex;
      flex-direction: row-reverse;
      align-content: center;
    }

    &__carouselContainer {
      width: 50%;
    }

    &__image {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      width: 50%;
    }

    &__copy {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;

      width: 50%;
      max-width: none;
      margin: 0;
      padding: 0 70px;

      text-align: left;

      h2 {
        @include font($size: rem(80), $name: 'section', $line-height: 1);

        margin-bottom: 37px;
      }

      p {
        @include font($size: rem(15), $name: 'body', $line-height: 1.6);
      }
    }

    &.-scrolling {
      margin: 0 rem(10);

      > .Share__scrolling {
        display: inline-block;
        padding: rem(10) 0;
        border: 10px solid transparent;
      }

      .Popover__container {
        top: 20px !important;
      }

      + div,
      + section {
        margin-top: rem(-80);
      }
    }

    &.-fomo {
      #{$root} {
        &__container {
          flex-direction: row;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  #{$root} {
    &__copy {
      h2 {
        font-size: rem(70);
      }
    }
  }
}
